import { ReactElement, useCallback, useRef, useState } from 'react';
import * as S from './ActivitiesPreview.styles';
import { numberWithCommaFormatter } from '@/common/app/utils/numberUtils';
import { IActivityWithAvailability } from '@/common/domain/Merge.domain';
import { Rating } from '../Rating';
import { useInView } from 'react-intersection-observer';
import { impressionInterceptor } from '@/shared/Analytics/GA4/ImpressionInterceptor';
import { AnalyticsInstance } from '@/common/service/analytics';
import { createCategoryNameFromListName } from '@/common/service/analytics/creators';
import Link from 'next/link';
import { BookedCount } from '@/common/ui/styled/BookedCount/BookedCount';
import {
    convertActivityDataToAnalyticsData,
    wraitDetasilsData,
} from '@/shared/Analytics/GA4/helpers';
import { trackSelectItem } from '@/shared/Analytics/GA4';
import { NewImagesGallery } from '../ImagesGallery/NewImagesGallery';
import { useAttractionsContext } from '@/common/app/contexts';

const DESCRIPTION_LIMIT = 100;

type ActivitiesPreviewProps = {
    activity: IActivityWithAvailability;
    position: number;
    listName?: string;
    listId?: string;
    disabled?: boolean;
    listNameGA3?: string;
    isLastItem?: boolean;
    innerRef?: (node?: Element | null | undefined) => void;
    isFromSearch?: boolean;
};

export const NewActivitiesPreview = ({
    activity,
    disabled,
    listName,
    listId,
    position,
    listNameGA3,
    innerRef,
    isLastItem,
}: ActivitiesPreviewProps): ReactElement => {
    const { loading } = useAttractionsContext();
    const [readMore, setReadMore] = useState(false);
    const reviewsCount = Number(activity.rate_count) || 0;
    const reviewsCountWithComma = numberWithCommaFormatter(reviewsCount);
    const scrollRef = useRef<HTMLDivElement>(null);

    const item_list_id = listId || (typeof window === 'undefined' ? '' : window.location.pathname);

    const activityData = convertActivityDataToAnalyticsData(
        activity,
        position,
        listName || '',
        item_list_id
    );

    const [ref] = useInView({
        triggerOnce: true,
        onChange: (inView) => {
            if (inView) {
                const categoryName = listNameGA3 ? createCategoryNameFromListName(listNameGA3) : '';

                impressionInterceptor.addImpression(
                    AnalyticsInstance.creators.createImpressionFromArticlePreview({
                        listName: listNameGA3 || '',
                        categoryName,
                        position,
                    })(activity),
                    true,
                    activityData
                );
            }
        },
        skip: loading.initLoading,
    });

    const readMoreEvent = useCallback(
        (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
            if (readMore) {
                scrollRef.current?.scrollTo(0, 0);
            }
            setReadMore(!readMore);
            e.stopPropagation();
            e.preventDefault();
        },
        [readMore]
    );

    const onDisabledClick = useCallback((e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const descriptionText = activity.description || activity.previewDescription || '';
    const longRead = descriptionText.length > DESCRIPTION_LIMIT;

    const clickHandler = () => {
        const item_list_name = listName || '';
        wraitDetasilsData({
            index: position + 1,
            item_list_name,
            item_list_id,
        });

        trackSelectItem({
            item_list_name,
            item_list_id,
            items: [
                {
                    ...activityData,
                },
            ],
        });
    };

    return (
        <Link href={activity.url} passHref prefetch={false}>
            <S.Container
                ref={innerRef}
                data-test-id="Activity_card"
                isLast={isLastItem}
                onClick={clickHandler}
            >
                {disabled && <S.Disabled onClick={onDisabledClick} />}
                <NewImagesGallery
                    activity={activity}
                    disabled={disabled}
                    reviewsCount={reviewsCount}
                    position={position + 1}
                    item_list_name={listName || ''}
                    item_list_id={item_list_id}
                    withPaddings
                    event
                />
                <S.ContentContainer isActive={readMore} ref={ref}>
                    <S.Title>{activity.name}</S.Title>
                    <S.DurationWrapper>
                        {!!reviewsCount && (
                            <>
                                <Rating
                                    rating={activity.rating_stars}
                                    data-test-id="Rating_start"
                                />
                                <S.RatingText data-test-id="Rating_text">{`${reviewsCountWithComma} ${
                                    reviewsCount > 1 || reviewsCount < 1 ? 'ratings' : 'rating'
                                }`}</S.RatingText>
                            </>
                        )}
                        {reviewsCount === 0 && <S.BeFirst>Be the first to review!</S.BeFirst>}
                        <S.Duration>
                            {!!activity.duration && (
                                <>
                                    <S.ClockIcon />
                                    {activity.duration}
                                </>
                            )}
                        </S.Duration>
                    </S.DurationWrapper>
                    <S.Description
                        data-test-id="Activity_description"
                        readMore={readMore}
                        ref={scrollRef}
                    >
                        {activity.special_status.mobile && (
                            <S.SpecialStatus>{activity.special_status.mobile}</S.SpecialStatus>
                        )}
                        {descriptionText}
                    </S.Description>
                    {longRead && (
                        <S.ReadMore
                            onClick={readMoreEvent}
                            isActive={readMore}
                            data-test-id="Read_more_activity_card"
                        >
                            {readMore ? 'See less' : 'Read more'}
                            <S.Arrow isActive={readMore} />
                        </S.ReadMore>
                    )}
                    <BookedCount recentPurchased={activity.recent_purchased} isFire />
                </S.ContentContainer>
            </S.Container>
        </Link>
    );
};
